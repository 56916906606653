import { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

function Login() {
	let history = useHistory();

	const [user_id, setUserId] = useState("");
	const [user_password, setUserPassword] = useState("");

	useEffect(() => {
		let checkSession = sessionStorage.getItem('user_type');
		if(checkSession === "user"){
			history.push({pathname:"/projectlist", state: { user_id: checkSession  } }); 
		} 
		if (checkSession === "odm") {
			history.push({pathname:"/odmhome", state: { user_id: checkSession  } }); 
		}
		if (checkSession === "employee") {
			history.push({pathname:"/projectlist", state: { user_id: checkSession  } }); 
		}

	}, [])

	const submitLogin = () => {
		const url = "http://localhost:3001/";
		Axios.post(url, { user_id: user_id, user_password: user_password }).then((response) => {
			if(response.data['user_type'] === "user"){ 
				sessionStorage.setItem('user_id', response.data['user_id']);
				sessionStorage.setItem('user_type', response.data['user_type']);
				sessionStorage.setItem('user_name', response.data['user_name']);
			}
			else if(response.data['user_type'] === "employee"){ 
				sessionStorage.setItem('user_id', response.data['user_id']);
				sessionStorage.setItem('user_type', response.data['user_type']);
				sessionStorage.setItem('user_name', response.data['user_name']);
				sessionStorage.setItem('team_id', response.data['team_id']);
			}
			else if(response.data['user_type'] === "odm"){ 
				sessionStorage.setItem('user_id', response.data['user_id']);
				sessionStorage.setItem('user_type', response.data['user_type']);
				sessionStorage.setItem('user_name', response.data['user_name']);
			}
			else { 
				alert("Invalid Login"); 
			}

		}).catch(err =>{ alert("Invalid Login"); console.log(err) });
	}

	return (
		<section className="section_login">
            <div className ="container">
                <div className="row">
                    <div className="col-md-6">
						<div className="card">
                			<div className="card-body">
                    			<h1 className="card-title text-center">LOGIN</h1>
								<form className="form-group">
									<div>
										<label>User ID</label>
										<input type="text" className="form-control" onChange={(e) => {setUserId(e.target.value)}}/>
									</div>
									<div>
										<label>Password</label>
										<input type="password" className="form-control" onChange={(e) => {setUserPassword(e.target.value)}}/>
									</div>
									<div className="inputButtonDet">
										<input type ="button" className="btn btnLoginDet" id="" onClick={ () => { submitLogin() } } value="Submit" />
									</div>
								</form>
			            	</div>
            			</div>
            		</div>
            	</div>
            </div>
		</section>
		);
	}
	
	export default Login;
	